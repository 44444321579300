import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { getReleaseFlags } from '../helpers/releaseFlags';

type BlackFridayHookParams = {
  lang?: string;
  zipCode?: string;
};

export default function useBlackFriday({ lang, zipCode }: BlackFridayHookParams) {
  const router = useRouter();
  const [isBlackFridayEnabled, setIsBlackFridayEnabled] = useState<boolean>(false);
  const [isOnBlackFridayHost, setIsOnBlackFridayHost] = useState<boolean>(false);

  const blackFridayLinkWithoutPostalCode =
    lang === 'en-us'
      ? 'en-us/weekly_ads/black-friday'
      : lang === 'fr-ca'
      ? 'fr-ca/circulaires/vendredi-fou'
      : 'en-ca/flyers/black-friday';

  const [blackFridayLink, setBlackFridayLink] = useState<string>(blackFridayLinkWithoutPostalCode);

  useEffect(() => {
    const { shouldUseBlackFridayDesign } = getReleaseFlags();
    setIsBlackFridayEnabled(shouldUseBlackFridayDesign);
    setIsOnBlackFridayHost(location.hostname === process.env.NEXT_PUBLIC_BF_HOSTNAME);
  }, []);

  useEffect(() => {
    if (zipCode) {
      setBlackFridayLink(`${blackFridayLinkWithoutPostalCode}?postal_code=${zipCode}`);
    }
  }, [zipCode]);

  return {
    isBlackFridayEnabled,
    isOnBlackFridayHost,
    blackFridayLink,
    isBlackFridayPage: router ? router.asPath.includes('black-friday') : false,
  };
}
